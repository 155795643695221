exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-faqs-js": () => import("./../../../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-gift-card-15-usd-digital-js": () => import("./../../../src/pages/gift-card/15-usd-digital.js" /* webpackChunkName: "component---src-pages-gift-card-15-usd-digital-js" */),
  "component---src-pages-gift-cards-js": () => import("./../../../src/pages/gift-cards.js" /* webpackChunkName: "component---src-pages-gift-cards-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-media-js": () => import("./../../../src/pages/media.js" /* webpackChunkName: "component---src-pages-media-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-products-index-js": () => import("./../../../src/pages/products/index.js" /* webpackChunkName: "component---src-pages-products-index-js" */),
  "component---src-pages-products-teddy-blaze-js": () => import("./../../../src/pages/products/teddy/blaze.js" /* webpackChunkName: "component---src-pages-products-teddy-blaze-js" */),
  "component---src-pages-products-teddy-luna-js": () => import("./../../../src/pages/products/teddy/luna.js" /* webpackChunkName: "component---src-pages-products-teddy-luna-js" */),
  "component---src-pages-products-teddy-theo-js": () => import("./../../../src/pages/products/teddy/theo.js" /* webpackChunkName: "component---src-pages-products-teddy-theo-js" */),
  "component---src-pages-return-policy-js": () => import("./../../../src/pages/return-policy.js" /* webpackChunkName: "component---src-pages-return-policy-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../../../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */)
}

